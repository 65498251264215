@charset "utf-8";
/* ================================================================================
トップページ
================================================================================ */

#indexTop {
  
  section {
    width: 100%;
  }
  .boxA{
    display: block;
    width: 100%;
    padding-top: 400px;
  }
}