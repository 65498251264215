@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
/* ================================================================================
importファイル
================================================================================ */
/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 100;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 300;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 400;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 500;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 700;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 900;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff");
}

/* ================================================================================
フォントサイズ VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/*PC設定*/
/* EXTEND
------------------------------------------------------------- */
/* ======================================================================

リセット

====================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* ================================================================================

印刷時の定義

================================================================================ */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 750px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 750px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 750px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

main article section p {
  font-family: YakuHanJP_Noto, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

a {
  color: #555;
}

p.txtDec {
  display: none;
}

/* 共通デザイン
------------------------------------------------------------- */
.modalLayout {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(51, 51, 51, 0.5);
  top: 0;
  left: 0;
}

.modalLayout .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 500px;
  display: flex;
  justify-content: center;
}

.modalLayout .inner button {
  display: block;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  margin: 0 15px;
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* ================================================================
ヘッダー
================================================================ */
header#header {
  background-color: beige;
  padding: 30px 0;
}

header#header .inner {
  display: flex;
}

header#header button {
  display: block;
  border: 1px solid #000;
  padding: 0 15px;
  cursor: pointer;
}

/* ================================================================================
フッター
================================================================================ */
footer#footer {
  background-color: burlywood;
  color: #fff;
  padding: 30px 0;
}

/* 見出し装飾
------------------------------------------------------------- */
/* アニメーション関連
------------------------------------------------------------- */
/* ボタン装飾
------------------------------------------------------------- */
button {
  outline: none;
}

/************************************
トップページ
***********************************/
/* ================================================================================
トップページ
================================================================================ */
#indexTop section {
  width: 100%;
}

#indexTop .boxA {
  display: block;
  width: 100%;
  padding-top: 400px;
}
