@charset "utf-8";

/* ================================================================
ヘッダー
================================================================ */

header#header {
  background-color: beige;
  padding: 30px 0;
	.inner{
		display: flex;
	}
	button{
		display: block;
		border: 1px solid #000;
		padding: 0 15px;
		cursor:pointer;
	}
}