@charset "utf-8";
/* ======================================================================
基本設定
===================================================================== */
* {
	box-sizing: border-box;
}
/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
	width: 1px;
	height: 0; // 横幅768pxまで
	@include mq(md) {
		width: 2px;
	}
}
/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
.pcOnly {
	@include mq(md) {
		display: none !important;
	}
}
.spOnly {
	display: none !important;
	@include mq(md) {
		display: block !important;
	}
}
html {
	font-size: $mainRemFontSize;
}
body {
	position: relative;
	font-family: $base-font;
	font-size: 1.6rem;
	font-weight: normal;
	color: #555;
}
.fixedBody {
	position: fixed !important;
	width: 100%;
	height: 100%;
}
/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	tap-highlight-color: rgba(0, 0, 0, 0);
}
main {
	position: relative;
	display: block;
	width: 100%;
	article {
		section {
			display: block;
			width: 100%;
			position: relative;
			p {
				font-family: $yakuhan;
			}
		}
	}
}
a {
	color: #555;
}
p.txtDec{
	display: none;
}
/* 共通デザイン
------------------------------------------------------------- */
.modalLayout {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(#333, .5);
	top: 0;
	left: 0;
	.inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		width: 500px;
		display: flex;
		justify-content:center;
		button{
			display: block;
			padding: 15px;
			border: 1px solid #ddd;
			background-color: #fff;
			cursor:pointer;
			margin:0 15px;
		}
	}
}
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */