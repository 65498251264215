@charset "utf-8";

/* ================================================================================
フッター
================================================================================ */

footer#footer {
  background-color: burlywood;
  color: #fff;
  padding: 30px 0;
}